import React, { useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { ClipLoader } from "react-spinners";
import './App.css'; // Importar los estilos CSS

const App = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Realizar la petición POST a la API
      const response = await axios.post("https://fantasystats.org:8443/api/v1/data_generator", {
        username,
        password,
      });

      const data = response.data;

      // Verificar si se recibieron datos
      if (Array.isArray(data) && data.length > 0) {
        // Convertir JSON a CSV utilizando PapaParse
        const csv = Papa.unparse(data);

        // Añadir BOM para UTF-8
        const utf8Bom = "\uFEFF";
        const csvWithBom = utf8Bom + csv;

        // Obtener el nombre de la liga del primer registro
        const leagueName = data[0].league_name ? data[0].league_name.trim() : "BigData_Fantasystats.org.csv";

        // Crear un blob con el CSV y descargarlo
        const blob = new Blob([csvWithBom], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${leagueName}.csv`);
      } else {
        setError("No se encontró información");
      }
    } catch (error) {
      console.error("Error al hacer la petición", error);
      setError("El usuario o la contraseña no son correctos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h1>fantasystats.org</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
        />
        <button type="submit" disabled={loading}>Login</button>
      </form>

      {loading && (
        <div className="loading-container">
          <ClipLoader size={50} color={"#fff"} loading={loading} />
          <p>Recopilando los datos, por favor tenga paciencia...</p>
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default App;
